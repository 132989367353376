<template>
  <div>
    <ValidationObserver ref="deliveryDetails">
      <div v-for="(product, index) in productDetails" :key="index">
        <div class="d-flex justify-content-between">
          <h2>Product Details {{ index + 1 }}</h2>
          <b-button v-if="index != 0" variant="warning" @click="removeProduct(index)"
            ><feather-icon icon="XIcon" size="14" /> Remove Product</b-button
          >
        </div>
        <b-row class="d-flex justify-content-center">
          <b-col v-if="product.commodity || product.commodity !== 'Other'" md="6">
            <b-form-group :label="'Select Commodity'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Commodity">
                <v-select
                  v-model="product.commodity"
                  :options="commodityOptions"
                  :clearable="false"
                  label="commodity_name"
                  placeholder="Select Commodity"
                  @input="checkForOther(index)"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col v-else md="6">
            <b-form-group :label="'Enter Commodity'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Commodity">
                <b-row
                  ><b-col md="2">
                    <v-select
                      v-model="product.commodity"
                      :options="commodityOptions"
                      :clearable="false"
                      label="commodity_name"
                      @input="checkForOther(index)" /></b-col
                  ><b-col>
                    <template>
                      <b-form-input
                        v-model="product.otherCommodity"
                        placeholder="Enter custom commodity"
                      />
                    </template>
                    <span class="text-danger">{{ errors[0] }}</span></b-col
                  ></b-row
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <ValidationProvider v-slot="{ errors }" rules="required" name="Cargo Type">
              <b-row v-if="categoryType === 'Air'">
                <b-col md="6">
                  <b-form-group :label="'Cargo Type'">
                    <v-select
                      v-model="product.packageType"
                      :options="packageOptions"
                      :clearable="false"
                      placeholder="Cargo Type"
                      label="package_type"
                      @input="checker(index)"
                    /> </b-form-group></b-col
                ><b-col>
                  <b-form-group :label="`${product.packageType} Options`">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="Package Type Options"
                    >
                      <v-select
                        v-model="product.packageTypeOption"
                        :options="boxOptions"
                        :clearable="false"
                        label="package_name"
                        placeholder="Select Box Type Option"
                      />

                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>
                  <span class="text-danger">{{ errors[0] }}</span></b-col
                ></b-row
              >
              <b-row v-else
                ><b-col md="6">
                  <b-form-group :label="'Select Cargo Type'">
                    <v-select
                      v-model="product.packageType"
                      :options="packageOptions"
                      :clearable="false"
                      placeholder="Select Cargo Type"
                      label="package_type"
                      @input="checker(index)"
                    /> </b-form-group></b-col
                ><b-col>
                  <b-form-group :label="`${product.packageType} Options`">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="Package Type Options"
                    >
                      <v-select
                        v-if="product.packageType === 'Less-than-Container Load (LCL) Type'"
                        v-model="product.packageTypeOption"
                        :options="boxOptions"
                        :clearable="false"
                        label="package_name"
                        placeholder="Select Box Type Option"
                      />
                      <v-select
                        v-else
                        v-model="product.packageTypeOption"
                        :options="containerOptions"
                        :clearable="false"
                        label="package_name"
                        placeholder="Select Box Type Option"
                      />

                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>
                  <span class="text-danger">{{ errors[0] }}</span></b-col
                ></b-row
              >
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <div v-if="product.packageType != 'Full-Container Load (FCL) Type'">
              <b-form-group :label="'Dimension'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="Dimension">
                  <b-input-group>
                    <b-form-input
                      :id="`length-${index}`"
                      v-model="product.length"
                      type="number"
                      class="border-right-0 text-center px-0"
                      :placeholder="`0`"
                    />
                    <b-input-group-text class="border-right-0 border-left-0 rounded-0 p-0">
                      <feather-icon icon="XIcon" />
                    </b-input-group-text>
                    <b-form-input
                      :id="`width-${index}`"
                      v-model="product.width"
                      type="number"
                      class="border-right-0 border-left-0 rounded-0 text-center px-0"
                      :placeholder="`0 `"
                    />
                    <b-input-group-text class="border-right-0 border-left-0 rounded-0 p-0">
                      <feather-icon icon="XIcon" />
                    </b-input-group-text>
                    <b-form-input
                      :id="`height-${index}`"
                      v-model="product.height"
                      type="number"
                      class="border-left-0 text-center px-0"
                      :placeholder="`0 `"
                    />
                    <select id="size-unit" v-model="product.unit" class="custom-select append-left">
                      <option value="unit" disabled selected hidden>unit</option>
                      <option value="cm">cm</option>
                      <option value="in">in</option>
                      <option value="ft">ft</option>
                    </select>
                  </b-input-group>

                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
            <div v-else>
              <b-form-group :label="'Dimension'" :disabled="true">
                <b-input-group>
                  <b-form-input
                    :id="`length-${index}`"
                    v-model="product.length"
                    type="number"
                    class="border-right-0 text-center px-0"
                    :placeholder="`0`"
                  />

                  <b-form-input
                    :id="`width-${index}`"
                    v-model="product.width"
                    type="number"
                    class="border-right-0 border-left-0 rounded-0 text-center px-0"
                    :placeholder="`0 `"
                  />

                  <b-form-input
                    :id="`height-${index}`"
                    v-model="product.height"
                    type="number"
                    class="border-left-0 text-center px-0"
                    :placeholder="`0 `"
                  />
                  <select id="size-unit" v-model="product.unit" class="custom-select append-left">
                    <option value="unit" disabled selected hidden>unit</option>
                    <option value="cm">cm</option>
                    <option value="in">in</option>
                    <option value="ft">ft</option>
                  </select>
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
          <b-col md="4">
            <div>
              <b-form-group :label="'Weight'">
                <b-input-group>
                  <b-form-input
                    :id="`weight-${index}`"
                    v-model="product.weight"
                    type="number"
                    min="0.5"
                    :placeholder="'weight'"
                  />
                  <b-input-group-append>
                    <b-form-select v-model="product.weight_unit">
                      <b-form-select-option value="kg"> kg </b-form-select-option>
                      <b-form-select-option value="lbs"> lbs </b-form-select-option>
                    </b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>

          <b-col md="4">
            <div>
              <b-form-group :label="'Quantity'">
                <input
                  v-model="product.quantity"
                  type="number"
                  class="form-control mr-2"
                  placeholder="Quantity"
                  aria-label="Quantity"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-col md="6">
            <b-form-group :label="'Invoice Value'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Invoice Value">
                <input
                  v-model="product.invoiceValue"
                  type="number"
                  class="form-control mr-2"
                  placeholder="Invoice Value"
                  aria-label="Invoice Value"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="'Insurance'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Insurance">
                <b-form-radio-group v-model="product.insurance">
                  <b-form-radio value="Yes"> Yes </b-form-radio>
                  <b-form-radio value="No"> No </b-form-radio>
                </b-form-radio-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <h2>Recipient Details {{ index + 1 }}</h2>
        <ValidationObserver ref="recipientDetails">
          <b-row>
            <b-col md="12">
              <b-form-group :label="'Name (or Company Name)'">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Name (or Company Name)"
                >
                  <input
                    v-model="product.recipient.name"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Name (or Company Name)"
                    aria-label="Name (or Company Name)"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group :label="'Email'">
                <ValidationProvider v-slot="{ errors }" rules="required|email" name="E-mail">
                  <input
                    v-model="product.recipient.email"
                    type="text"
                    class="form-control mr-2"
                    placeholder="E-mail"
                    aria-label="E-mail"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Contact Number'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="Contact Number">
                  <input
                    v-model="product.recipient.phone"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Contact Number"
                    aria-label="Contact Number"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="deliveryType === 'Door to Door'">
            <b-row>
              <b-col md="12">
                <b-form-group :label="'Unit Number'">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Unit Number">
                    <input
                      v-model="product.recipient.address.unitNumber"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Unit Number"
                      aria-label="Unit Number"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group :label="'Address Line 1'">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Address Line 1">
                    <input
                      v-model="product.recipient.address.addressLine1"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Address Line 1"
                      aria-label="Address Line 1"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group :label="'Address Line 2'">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Address Line 2">
                    <input
                      v-model="product.recipient.address.addressLine2"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Address Line 2"
                      aria-label="Address Line 2"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group :label="'Postal Code'">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Postal Code">
                    <input
                      v-model="product.recipient.address.postalCode"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Postal Code"
                      aria-label="Postal Code"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="'Area'">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Area">
                    <input
                      v-model="product.recipient.address.area"
                      type="text"
                      class="form-control mr-2"
                      placeholder="Area"
                      aria-label="Area"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group :label="'State'">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="State">
                    <input
                      v-model="product.recipient.address.state"
                      type="text"
                      class="form-control mr-2"
                      placeholder="State"
                      aria-label="Country"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="'Country'">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Country">
                    <input
                      v-model="product.recipient.address.country"
                      type="text"
                      class="form-control mr-2"
                      placeholder="State"
                      aria-label="Country"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-if="deliveryType !== 'Door to Door'">
            <h2>
              Destination Port {{ index + 1 }}
              <slot name="label">
                <feather-icon
                  :id="`size-tooltip-${index}`"
                  style="margin-bottom: 5px"
                  size="20"
                  icon="InfoIcon"
                  :variant="'primary'"
                />
                <b-tooltip :target="`size-tooltip-${index}`" triggers="hover" no-fade>
                  Choose a port from port name and the fields will auto complete itself.
                </b-tooltip>
              </slot>
            </h2>
            <b-row>
              <b-col md="6">
                <b-form-group :label="'Country'">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Country">
                    <v-select
                      v-model="product.destination.country"
                      :options="port"
                      :clearable="false"
                      label="country"
                      @input="handleCountryChange(product.destination.country)"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="'Port Name'">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Port Name">
                    <v-select
                      v-model="product.destination"
                      :options="ports"
                      :clearable="false"
                      label="name"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </ValidationObserver>
        <hr />
      </div>
      <div class="d-flex justify-content-center">
        <b-button variant="primary" class="my-2" @click="addProduct">
          <feather-icon icon="PlusIcon" size="14" /> Add Product</b-button
        >
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  name: 'DeliveryDetails',
  components: {
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    deliveryType: {
      type: String,
    },
    categoryType: {
      type: String,
    },
    port: {
      type: Array,
    },
  },
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'mr-2',
      },
      productDetails: [
        {
          recipient: { address: {} },
          destination: {},
          unit: 'cm',
          weight_unit: 'kg',
          packageType: '',
        },
      ],
      commodityOptions: [
        'Food Stuff (Frozen)',
        'Food Stuff (Chill)',
        'Food Stuff (Dry)',
        'Perishable Vegetables',
        'Perishable Seafoods',
        'Perishable Meats',
        'Perishable Fruits',
        'Other',
      ],
      boxOptions: ['Foam Box', 'Normal Box', 'Carton', 'Jumbo Box', 'Crate', 'Pallet'],
      airOptions: ['Less-than-Container Load (LCL) Type'],
      seaOptions: ['Less-than-Container Load (LCL) Type', 'Full-Container Load (FCL) Type'],
      packageOptions: [],
      containerOptions: [
        '20’ General Purpose',
        '40’ General Purpose',
        '40’ High Cube',
        '20’ Refrigerated Container',
        '40’ Refrigerated Container',
      ],
      options: [],
      country: '',
      ports: [],
      packageTypeOption: null,
    }
  },
  watch: {
    categoryType(newCategoryType) {
      if (newCategoryType === 'Air') {
        this.packageOptions = this.airOptions
      } else {
        this.packageOptions = this.seaOptions
      }
    },
  },
  methods: {
    checker(index) {
      if (this.productDetails[index].packageType === 'Full-Container Load (FCL) Type') {
        delete this.product.packageTypeOption
      }
      if (this.productDetails[index].packageType === 'Less-than-Container Load (LCL) Type') {
        delete this.productDetails[index].weight
        delete this.productDetails[index].quantity
      }
    },
    checkForOther(index) {
      if (this.productDetails[index].commodity !== 'Other') {
        delete this.productDetails[index].otherCommodity
      }
    },
    addProduct() {
      if (this.deliveryType !== 'Door to Door') {
        this.productDetails.push({
          recipient: {},
          destination: {},
          unit: 'cm',
          weight_unit: 'kg',
        })
      } else {
        this.productDetails.push({
          recipient: { address: {} },
          unit: 'cm',
          weight_unit: 'kg',
        })
      }
    },
    removeProduct(index) {
      this.productDetails.splice(index, 1)
    },
    async handleCountryChange(country) {
      const type = { type: this.categoryType, country: country.country }
      const response = await this.$http.post('get_port_by_country ', type)
      if (response.data.status) {
        this.ports = response.data.data
      }
    },
    childMethod() {
      return new Promise((resolve, reject) => {
        let result = ''
        this.$refs.deliveryDetails.validate().then((success) => {
          if (success) {
            if (this.deliveryType !== 'Door to Door') {
              if (this.productDetails[0].recipient.address) {
                delete this.productDetails[0].recipient.address
              }
            } else {
              delete this.productDetails[0].destination
            }
            this.$emit('setForm', this.productDetails)
            result = true
            resolve(result)
          } else {
            result = false
          }
          resolve(result)
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
