<template>
  <div>
    <b-overlay :show="isSubmitting" rounded="sm">
      <form-wizard
        :title="null"
        :subtitle="null"
        :back-button-text="'previous'"
        :next-button-text="'next'"
        @on-complete="onSubmit"
      >
        <tab-content
          :title="'Delivery Details'"
          icon="feather icon-file-text"
          :before-change="validateFirstSteps"
        >
          <ValidationObserver ref="deliveryDetails" v-slot="{ passes }">
            <form @submit.prevent="passes(validateFirstSteps)">
              <b-form-group :label="'Choose your category'">
                <b-row class="mb-2">
                  <b-col cols="12">
                    <b-row class="mb-1">
                      <b-col sm="6" md="5" lg="6">
                        <CategoryType
                          :id="'sea'"
                          :value="'Sea'"
                          :title="'SEA'"
                          :description="'Deliver your goods via sea'"
                          :form="orderForm"
                          @setCategoryType="handleSetCategoryType"
                        />
                      </b-col>
                      <b-col sm="6" md="5" offset-md="2" lg="6" offset-lg="0" class="d-flex">
                        <CategoryType
                          :id="'air'"
                          :value="'Air'"
                          :title="'AIR'"
                          :description="'Deliver your goods via air'"
                          :form="orderForm"
                          @setCategoryType="handleSetCategoryType"
                        />
                      </b-col>
                    </b-row>
                  </b-col> </b-row
              ></b-form-group>
              <hr />
              <DeliveryDetails
                ref="childComponentRef"
                :type-of-order="orderForm.categoryType"
                @setForm="handleSetForm"
              />
            </form>
          </ValidationObserver>
        </tab-content>

        <tab-content
          :title="'Origin Details'"
          icon="feather icon-user"
          :before-change="validateSecondSteps"
        >
          <form @submit.prevent="passes(validateSecondSteps)">
            <SenderDetails
              ref="senderComponentRef"
              :delivery-type="orderForm.deliveryType"
              :port="port"
              :category-type="orderForm.categoryType"
              @setSender="handleSetSender"
              @setOrigin="handleSetOrigin"
            />
          </form>
        </tab-content>
        <tab-content :title="'Product & Destination Details'" icon="feather icon-shopping-cart">
          <ProductRecipientDetails
            ref="productRecipientDetails"
            :port="port"
            :delivery-type="orderForm.deliveryType"
            :category-type="orderForm.categoryType"
            @setForm="handleSetDestination"
          />
        </tab-content>
        <b-button slot="finish" class="wizard-btn" variant="primary">
          <b-spinner class="mr-50" small />
          Get A Quotation
        </b-button>
      </form-wizard>
      <b-modal v-model="isConfirmationModalVisible" hide-header centered>
        <p>Quotation will take 1-2 days to be provided. Confirm?</p>
        <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel"> Cancel </b-button>
          <b-button variant="primary" @click="createDelivery()"> Confirm </b-button>
        </template>
      </b-modal>
      <template #overlay>
        <div class="text-center">
          <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner" variant="primary" />
          <h3 class="mt-3">Processing... Please wait.</h3>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { BRow, BCol } from 'bootstrap-vue'
import {
  ProductRecipientDetails,
  DeliveryDetails,
  CategoryType,
  SenderDetails,
} from '@/components/Fleet/CreateDelivery'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    ProductRecipientDetails,
    DeliveryDetails,
    ValidationObserver,
    CategoryType,
    SenderDetails,
  },
  data() {
    return {
      isSubmitting: false,
      isConfirmationModalVisible: false,
      orderForm: {
        categoryType: '',
        movementArrangement: [''],
      },
      port: [],
    }
  },
  computed: {
    loadingMessage() {
      if (this.isLoading) {
        return 'Fetching fields data...'
      }

      if (this.isCheckout) {
        return 'Processing Order...'
      }
      return ''
    },
  },
  created() {
    this.getPort()
  },
  methods: {
    async getPort() {
      const type = { type: this.orderForm.categoryType }
      const response = await this.$http.post('get_all_port_countries ', type)
      if (response.data.status) {
        this.port = response.data.data
      }
    },
    async onSubmit() {
      try {
        const result = await this.$refs.productRecipientDetails.childMethod()
        return new Promise((resolve, reject) => {
          if (result) {
            this.isConfirmationModalVisible = true
            resolve(true)
          } else {
            reject()
          }
        })
      } catch (error) {
        console.error('Error:', error)
      }
    },
    async createDelivery() {
      this.isSubmitting = true
      const response = await this.$http.post('fleet_create', this.orderForm)
      if (response.status == 200) {
        this.$router.push({
          name: 'fleetforwardingdeliverysuccess',
        })
        this.isSubmitting = false
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
        this.isSubmitting = false
      }
    },
    async validateFirstSteps() {
      try {
        if (this.orderForm.categoryType === '') {
          await this.$swal('Error', 'Category Type cannot be empty', 'error')
          return false
        }
        const result = await this.$refs.childComponentRef.childMethod()
        return new Promise((resolve, reject) => {
          if (result) {
            resolve(true)
          } else {
            reject()
          }
        })
      } catch (error) {
        console.error('Error:', error)
      }
    },
    async validateSecondSteps() {
      try {
        const result = await this.$refs.senderComponentRef.childMethod()
        return new Promise((resolve, reject) => {
          if (result) {
            resolve(true)
          } else {
            reject()
          }
        })
      } catch (error) {
        console.error('Error:', error)
      }
    },
    handleSetCategoryType(value) {
      this.orderForm.categoryType = value
      this.getPort()
    },
    handleSetForm(value) {
      this.orderForm = {
        ...this.orderForm,
        ...value,
      }
    },
    handleSetDestination(value) {
      this.orderForm.order = value
    },
    handleSetSender(value) {
      delete this.orderForm.sender
      this.orderForm.sender = value
    },
    handleSetOrigin(value) {
      if (this.orderForm.deliveryType === 'Door to Door') {
        delete this.orderForm.origin
      } else {
        this.orderForm.origin = value
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
