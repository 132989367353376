<template>
  <div>
    <h2>Sender Details</h2>
    <ValidationObserver ref="senderDetails">
      <b-row>
        <b-col md="12">
          <b-form-group :label="'Name (or Company Name)'">
            <ValidationProvider v-slot="{ errors }" rules="required" name="Name (or Company Name)">
              <input
                v-model="sender.name"
                type="text"
                class="form-control mr-2"
                placeholder="Name (or Company Name)"
                aria-label="Name (or Company Name)"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group :label="'Email'">
            <ValidationProvider v-slot="{ errors }" rules="required|email" name="E-mail">
              <input
                v-model="sender.email"
                type="text"
                class="form-control mr-2"
                placeholder="E-mail"
                aria-label="E-mail"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="'Contact Number'">
            <ValidationProvider v-slot="{ errors }" rules="required" name="Contact Number">
              <input
                v-model="sender.phone"
                type="text"
                class="form-control mr-2"
                placeholder="Contact Number"
                aria-label="Contact Number"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="deliveryType === 'Door to Door'">
        <b-row>
          <b-col md="12">
            <b-form-group :label="'Unit Number'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Unit Number">
                <input
                  v-model="address.unitNumber"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Unit Number"
                  aria-label="Unit Number"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group :label="'Address Line 1'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Address Line 1">
                <input
                  v-model="address.addressLine1"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Address Line 1"
                  aria-label="Address Line 1"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group :label="'Address Line 2'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Address Line 2">
                <input
                  v-model="address.addressLine2"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Address Line 2"
                  aria-label="Address Line 2"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group :label="'Postal Code'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Postal Code">
                <input
                  v-model="address.postalCode"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Postal Code"
                  aria-label="Postal Code"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="'Area'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Area">
                <input
                  v-model="address.area"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Area"
                  aria-label="Area"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group :label="'State'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="State">
                <input
                  v-model="address.state"
                  type="text"
                  class="form-control mr-2"
                  placeholder="State"
                  aria-label="State"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="'Country'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Country">
                <input
                  v-model="address.country"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Country"
                  aria-label="Country"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="deliveryType !== 'Door to Door'">
        <h2>
          Origin Port
          <slot name="label">
            <feather-icon
              :id="`size-tooltip`"
              style="margin-bottom: 5px"
              size="20"
              icon="InfoIcon"
              :variant="'primary'"
            />
            <b-tooltip :target="`size-tooltip`" triggers="hover" no-fade>
              Choose a port from port name and the fields will auto complete itself.
            </b-tooltip>
          </slot>
        </h2>
        <b-row>
          <b-col md="6">
            <b-form-group :label="'Country'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Country">
                <v-select
                  v-model="country"
                  :options="port"
                  :clearable="false"
                  label="country"
                  @input="handleCountryChange"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="'Port Name'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Port Name">
                <v-select v-model="origin" :options="ports" :clearable="false" label="name" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    deliveryType: {
      type: String,
    },
    categoryType: {
      type: String,
    },
    port: {
      type: Array,
    },
  },
  data() {
    return {
      sender: {},
      origin: {},
      country: '',
      ports: [],
      address: {},
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'mr-2',
      },
    }
  },
  methods: {
    async handleCountryChange() {
      const type = { type: this.categoryType, country: this.country.country }
      const response = await this.$http.post('get_port_by_country ', type)
      if (response.data.status) {
        this.ports = response.data.data
      }
    },
    childMethod() {
      return new Promise((resolve, reject) => {
        let result = ''
        this.$refs.senderDetails.validate().then((success) => {
          if (success) {
            if (this.deliveryType !== 'Door to Door') {
              if (this.sender.address) {
                delete this.sender.address
              }
            } else {
              this.sender.address = this.address
            }
            this.$emit('setSender', this.sender)
            this.$emit('setOrigin', this.origin)
            result = true
            resolve(result)
          } else {
            result = false
          }
          resolve(result)
        })
      })
    },
  },
}
</script>
